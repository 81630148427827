import { createStore } from 'vuex'

import app from '@/store/modules/app'
import appConfig from '@/store/modules/appConfig'
import features from '@rosfines/vue-common/vue/store/features'
import fineList from '@/store/modules/fineList'
import taxList from '@/store/modules/taxList'
// import GBFeatures from '@/store/GBFeatures';
import profile from '@/store/modules/profile'

const store = createStore({
	strict: true,
	modules: {
		app,
		appConfig,
		features,
		fineList,
		taxList,
		// GBFeatures,
		profile,
	},
	state() {
		return {
			globalLoading: true,
		}
	},
	mutations: {
		setGlobalLoading(state, val) {
			state.globalLoading = !!val
		},
	},
	actions: {
		async init({ dispatch, commit }, { loadProfile = true }) {
			dispatch('appConfig/loadAppConfig', undefined, { root: true })
			await Promise.all([
				loadProfile ? dispatch('profile/getProfile', undefined, { root: true }) : undefined,
				dispatch(
					'features/loadFeaturesList',
					{
						channel: localStorage.getItem('channel'),
					},
					{ root: true }
				),
			])
				.then(() => {
					commit('setGlobalLoading', false)
				})
				.catch(e => {
					console.log(e)
					throw new Error('init failed')
				})
		},
	},
})

export default store
