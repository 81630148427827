import finesApi from '@rosfines/vue-common/common/api/finesApi'

let loadingProfileResolve
const loadingProfile = new Promise(resolve => {
	loadingProfileResolve = resolve
})

export default {
	namespaced: true,
	state() {
		return {
			profiles: [],
		}
	},
	getters: {
		getByStsId: state => stsId => {
			for (const i in state.profiles) {
				const profile = state.profiles[i]
				const found = profile.cars.find(car => `${car.sts.id}` === `${stsId}`)
				if (found) {
					return found
				}
			}
			return {}
		},
	},
	mutations: {
		setProfiles(state, profiles) {
			state.profiles = profiles
			loadingProfileResolve()
		},
		addProfile(state, profile) {
			state.profiles.push(profile)
			loadingProfileResolve()
		},
		addCar(state, car) {
			state.profiles[0].cars.push(car)
		},
	},
	actions: {
		waitLoadingProfile() {
			return loadingProfile
		},
		async createProfile({ commit }) {
			return new Promise(async (resolve, reject) => {
				finesApi
					.getProfile()
					.then(res => {
						if (res) {
							commit('addProfile', res)
						}
						resolve(res)
					})
					.catch(e => {
						reject(e.data.error)
					})
			})
		},
		async getProfile({ commit }) {
			return new Promise(async (resolve, reject) => {
				finesApi
					.getProfile()
					.then(response => {
						if (response?.profiles?.length === 0) {
							finesApi
								.createProfile()
								.then(newProfile => {
									if (newProfile) {
										commit('setProfiles', [newProfile.profile])
									}
								})
								.catch(e => {
									reject(e)
								})
						} else if (response) {
							commit('setProfiles', response.profiles)
						}
						resolve(response.profiles)
					})
					.catch(e => {
						console.log(e)
						reject(e.data.error)
					})
			})
		},

		async getProfileDocumentId({ state }) {
			if (state.profiles[0]?.profileDocuments?.length) {
				return state.profiles[0].profileDocuments[0].id
			}
			const profileId = state.profiles[0].id
			const newDoc = await finesApi.post(`/profile/${profileId}/document`, {})
			if (finesApi.isSuccess(newDoc)) {
				return newDoc.data.response?.profileDocument.id
			}
			return false
		},

		async addDriverLicense({ dispatch }, { driverLicense = null }) {
			return new Promise(async (resolve, reject) => {
				dispatch('getProfileDocumentId')
					.then(profileDocumentId => {
						if (profileDocumentId) {
							finesApi
								.post(`profile/document/${profileDocumentId}/driverLicense`, {
									driverLicense,
								})
								.then(newLicense => {
									if (finesApi.isSuccess(newLicense)) {
										resolve(newLicense.data.response)
									} else {
										console.log('!success', newLicense)
										reject(newLicense.data.error)
									}
								})
								.catch(e => {
									console.log('!request')
									reject(e.data.error)
								})
						}
					})
					.catch(e => {
						reject(e.data.error)
					})
			})
		},

		async addCar({ state, commit }, { vehiclePlateNumber = null, vehiclePlateRegion = null }) {
			return new Promise(async (resolve, reject) => {
				finesApi
					.addCar({
						profileId: state.profiles[0].id,
						vehiclePlateNumber,
						vehiclePlateRegion,
					})
					.then(res => {
						commit('addCar', res.car)
						resolve(res.car)
					})
					.catch(e => {
						reject(e.data.error)
					})
			})
		},
		async addSts({ commit }, { profileId = null, vehiclePlateNumber = null, vehiclePlateRegion = null, stsNumber = null, organizationId = null }) {
			return new Promise(async (resolve, reject) => {
				finesApi
					.addSts({
						profileId,
						vehiclePlateNumber,
						vehiclePlateRegion,
						stsNumber,
						organizationId,
					})
					.then(res => {
						commit('addCar', res.car)
						resolve(res.car)
					})
					.catch(e => {
						reject(e.data.error)
					})
			})
		},
		async addCarNoSts({ commit, state }, { vehiclePlateNumber = '', vehiclePlateRegion = '' }) {
			return new Promise(async (resolve, reject) => {
				const profileId = state.profiles[0].id
				const carToUpdate = state.profiles[0].cars.find(car => !car.sts || !car.sts.stsNumber)
				const newCar = carToUpdate
					? await finesApi
							.patch(`profile/car/${carToUpdate.id}`, {
								vehiclePlateNumber,
								vehiclePlateRegion,
							})
							.catch(e => {
								reject(e.data.error)
							})
					: await finesApi
							.post(`profile/${profileId}/car`, {
								vehiclePlateNumber,
								vehiclePlateRegion,
							})
							.catch(e => {
								reject(e.data.error)
							})
				if (finesApi.isSuccess(newCar)) {
					commit('addCar', newCar.data.response.car)
					resolve(newCar.data.response.car)
				} else {
					reject(newCar.data.error)
				}
			})
		},
		async addStsReminder({ state }) {
			return new Promise(async (resolve, reject) => {
				const car = state.profiles[0].cars.find(item => !item.sts || !item.sts.stsNumber)
				if (car && car.id) {
					const res = await finesApi.post(`/pushNotification/addSts?car_id=${car.id}`).catch(e => {
						console.error(e.data.error)
						reject(e.data.error)
					})
					if (finesApi.isSuccess(res)) {
						resolve(res.data.response)
					} else {
						console.error(res.data.error)
						reject(res.data.error)
					}
				} else {
					reject()
				}
				reject()
			})
		},
		async userTransfer(context, { code = '' }) {
			return new Promise(async (resolve, reject) => {
				finesApi
					.userTransfer({ code })
					.then(res => {
						resolve(res)
					})
					.catch(e => {
						reject(e.data.error)
					})
			})
		},
	},
}
