import CoreWebFine from '@rosfines/core-web/entities/Fine'
import moment from 'moment'
import { FINE_STATUS } from '@/constants/fineStatus'
import currencyHelper from '@rosfines/core-web/helpers/currency'
import DeviceStorageInstance from '@/utils/deviceStorage'

export default class Fine extends CoreWebFine {
	is_overdue = false

	deletedByUser = false

	isOrderFine = false

	discountInfo = null

	accrualOriginalAmount = null

	stsId = null

	number = null

	imgUrl = null

	constructor(fine) {
		super(fine)

		this.imgUrl = fine.imgUrl
		this.number = fine.number
		this.offenceDateTime = fine.offenceDateTime
		this.statementDate = Fine.fineDate(fine.date || fine.statementDate || fine.offenceDateTime || fine.createdTime)
		this.discountInfo = fine.discountInfo
		this.stsId = fine.stsId
		this.is_overdue = !!fine.is_overdue
		this.deletedByUser = !!fine.deletedByUser
		this.isOrderFine = !!fine.isOrderFine
		this.accrualOriginalAmount = currencyHelper.normalize(fine.accrualOriginalAmount)
		this.statusLabel = this.getStatusLabel()
	}

	get isOverdue() {
		return this.is_overdue
	}

	hasDiscount() {
		return !!this.discountInfo?.discountUntil && moment().isSameOrBefore(this.discountInfo?.discountUntil)
	}

	isAdditionalAccrual() {
		return this.status === FINE_STATUS.ADDITIONAL_ACCRUAL
	}

	isPaid() {
		return [FINE_STATUS.PAID, FINE_STATUS.CLOSED, FINE_STATUS.EXPIRED].includes(this.status)
	}

	isMovedToOrder() {
		return this.status === FINE_STATUS.MOVED_TO_ORDER
	}

	isInProgress() {
		return this.status === FINE_STATUS.IN_PROGRESS
	}

	isNotPaid() {
		return this.status === FINE_STATUS.NOT_PAID || this.isAdditionalAccrual()
	}

	isViewed() {
		return DeviceStorageInstance.viewedFines.includes(this.id)
	}

	markAsViewed() {
		DeviceStorageInstance.viewedFines = this.id
	}

	getStatusLabel() {
		switch (this.status) {
			case 'notpaid':
				return 'Не оплачен'
			case 'in_progress':
				return 'В процессе оплаты'
			case 'closed':
				return 'Погашен'
			case 'paid':
				return 'Оплачен'
			default:
				return ''
		}
	}
}
