<template>
	<template v-if="globalLoading">
		<loader-block />
	</template>
	<router-view v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import finesApi from '@rosfines/vue-common/common/api/finesApi'
import store from '@/store'
import LoaderBlock from '@/components/blocks/LoaderBlock'

import '@rosfines/vue-common/common/services/webToNativeBridgeClient'
import tracking from '@/utils/tracking'

const GET = new URL(window.location.href).searchParams
if (GET.get('userId') && GET.get('rand') && GET.get('session')) {
	const params = {
		response: {
			userId: GET.get('userId'),
			rand: GET.get('rand'),
			session: GET.get('session'),
		},
	}
	finesApi.updateRequestParams(params, false)
}
if (GET.get('channel')) {
	localStorage.setItem('channel', GET.get('channel'))
}

// тут костыль c loadProfile для корректной работы предоплаты на iOS, на предоплате вообще не грузим профиль
// это должно сработать в вебвью при работе отдельно изолированно только этой страницы
store
	.dispatch('init', {
		loadProfile: !['/payment', '/taxes/documents/add'].includes(window.location.pathname),
	})
	.catch(() => {
		alert('Ошибка загрузки, попробуйте позже')
	})

if (GET.get('segment')) {
	store.commit('app/setSegment', GET.get('segment'))
}

export default {
	name: 'App',
	components: {
		LoaderBlock,
	},
	computed: {
		...mapState({
			globalLoading: state => state.globalLoading,
		}),
	},
	async mounted() {
		if (localStorage.getItem('channel') === 'AndroidReleaseGibdd') {
			document.body.setAttribute('data-color-scheme', 'blue')
		}
		await store.dispatch('features/waitLoadingFeaturesList')
		tracking.featuresList = store.getters['features/featuresList']
		tracking.featuresLoaderResolve()
	},
	methods: {
		...mapActions({
			loadFeaturesList: 'features/loadFeaturesList',
		}),
		...mapMutations({
			setGlobalLoading: 'setGlobalLoading',
		}),
	},
}
</script>
